.ats-switch {
  border: 1px solid;
  cursor:pointer;
  display:inline-block;
  text-align:left;
  overflow:hidden;
  line-height: 8px;
  min-width: 100px;
}
.ats-switch.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;

}
.ats-switch.disabled .switch-left,
.ats-switch.disabled .switch-right,
.ats-switch.disabled .knob {
  cursor: default !important;

}
.ats-switch span {
  cursor:pointer;
  display:inline-block;
  float:left;
  height: 100%;
  line-height: 20px;
  padding: 4px;
  text-align:center;
  width: 33%;
  white-space:nowrap;
  box-sizing:border-box;
  -o-box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;

}
.ats-switch > div {
  position:relative;
  width: 150%;

}
.ats-switch .knob {
  background:red;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #f5f5f5;
  width: 34%;
  z-index: 100;

}
.ats-switch .switch-on {
  left: 0%;

}
.ats-switch .switch-off {
  left: -50%;

}
.ats-switch .swtich-left,
.ats-switch .switch-right {
  z-index: 1;

}
.ats-switch .switch-left {
  color: #fff;
  background: #005fcc;

}
.ats-switch .switch-right {
  color: #333;
  background: #f0f0f0;

}
.ats-switch .switch-animate {
  transition:left 0.5s;
  -o-transition:left 0.5s;
  -moz-transition:left 0.5s;
  -webkit-transition:left 0.5s;

}
.ats-switch {
  display:inline-block;
  cursor:pointer;
  border-radius: 4px;
  border: 1px solid;
  border-color: #cccccc;
  position:relative;
  text-align:left;
  overflow:hidden;
  line-height: 8px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -o-user-select:none;
  user-select:none;
  vertical-align:middle;
  min-width: 100px;
  -webkit-transition:border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition:border-color ease-in-out .15s, box-shadow ease-in-out .15s;

}
.ats-switch:hover {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);

}
.ats-switch.disabled:hover {
  border-color: #cccccc;
  -webkit-box-shadow:inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);
  box-shadow:inset 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);

}
.ats-switch .knob {
  border-right:none;
  border-left:none;
  text-align:center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  background: #ffffff;

}
.ats-switch .knob i {
  color: #000;
  text-shadow: 0 1px 0 #fff;
  line-height: 18px;
  pointer-events:none;

}
.ats-switch .switch-left,
.ats-switch .switch-right,
.ats-switch .knob {
  min-height: 28px;

}
.ats-switch.switch-mini {
  min-width: 72px;

}
.ats-switch.switch-mini .switch-left,
.ats-switch.switch-mini .switch-right,
.ats-switch.switch-mini .knob {
  padding-bottom: 4px;
  padding-top: 4px;
  font-size: 10px;
  line-height: 9px;
  min-height: 18px;

}
.ats-switch.switch-mini i.switch-mini-icons {
  height: 1.20em;
  line-height: 9px;
  vertical-align:text-top;
  text-align:center;
  transform: scale(0.6);
  margin-top: -1px;
  margin-bottom: -1px;

}
.ats-switch.switch-small {
  min-width: 80px;

}
.ats-switch.switch-small .switch-left,
.ats-switch.switch-small .switch-right,
.ats-switch.switch-small .knob {
  padding-bottom: 3px;
  padding-top: 3px;
  font-size: 12px;
  line-height: 18px;
  min-height: 24px;

}
.ats-switch.switch-large {
  min-width: 120px;

}
.ats-switch.switch-large .switch-left,
.ats-switch.switch-large .switch-right,
.ats-switch.switch-large .knob {
  padding-bottom: 9px;
  padding-top: 9px;
  font-size: 16px;
  line-height:normal;
  min-height: 34px;

}
.ats-switch span.switch-left {
  color: #fff;
  background: #428bca;

}
.ats-switch span.switch-right {
  color: #000;
  background: #eeeeee;

}
.ats-switch.switch-primary span.switch-left {
  color: #fff;
  background: #428bca;

}
.ats-switch.switch-info span.switch-left {
  color: #fff;
  background: #5bc0de;

}
.ats-switch.switch-success span.switch-left {
  color: #fff;
  background: #5cb85c;

}
.ats-switch.switch-warning span.switch-left {
  background: #f0ad4e;
  color: #fff;

}
.ats-switch.switch-danger span.switch-left {
  color: #fff;
  background: #d9534f;

}
.ats-switch.switch-default span.switch-left {
  color: #000;
  background: #eeeeee;

}