.message-container {
  .messagebold {
    font-weight: bold;
  }
}
.align-items-center {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.notification-indicator {
  ul {
    li {
      padding: 0 !important;
      a {
        padding: 1rem 1.6rem !important;
        display: flex;
        flex-direction: column;
        line-height: 1.15rem;
        &.btn-view-more {
          color: #18a689;
          flex-direction: row;
          justify-content: space-between;
          .mini-loader {
            margin: 0;
            height: 16px;
            width: 16px;
          }
        }
        .message-title {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.list-rider {
  .alignLeft {
    float: left;
  }
}

.nopadding {
  padding: 0px !important;
}
.crossMe {
  font-size: 20px;
  font-weight: bolder;
  padding: 1px 0px 1px 3px;
  cursor: pointer;
  color: #4ab494;
  position: absolute;
  margin-top: -7px;
}
.addressFieldContainer {
  padding-top: 10px;
}
.addressField {
  padding: 1px 5px 1px 5px !important;
  background-color: #1ab394 !important;
  color: white !important;
  border-radius: 4px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  max-width: 95% !important;
  white-space: nowrap;
  display: inline-block;
}
