.create-appointment {
  .map-container {
    position: relative;
    position: sticky;
    top: 15px;
    width: 50%;
    padding-left: 10px;
    height: calc(100vh - 30px);
    .google-map-overlay {
      position: absolute;
      background-color: black;
      bottom: 0;
      width: 90%;
      opacity: 0.8;
      color: white;
      font-weight: bold;
      padding: 5px;
      font-size: 15px;
      text-align: center;
      left: 5%;
    }
  }
}

.inline-ui-select-custom .ui-select-container .form-control.ui-select-toggle, .inline-ui-select-custom .ui-select-container .form-control.ui-select-search {
  width: 100%;
}

.inline-ui-select-custom .ui-select-container .ui-select-choices.dropdown-menu {
  min-width: auto;
}

hr.less-height {
  margin-top: 10px;
  margin-bottom: 10px;
}
