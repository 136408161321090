.filter-receipt-detail-logs {
    .ibox-title {
        position: relative;
        margin-bottom: 10px;
    }

    .ibox-tools {
        position: absolute;
        top: 15px;
        right: 15px;
    }


    margin-top: 10px;
    margin-bottom: 0px;

    .date-range {
        background-color: #ffffff !important;
    }

    .search-button {
        margin-right: 15px;
    }
}

.receipt-detail-logs {
    .td-table-logs {
        padding-left: 0;
        padding-right: 0;

        .table-logs {
            margin-bottom: 0;
            width: 100%;
            th{
             height: 50px;
            }
            .text-center {
                vertical-align: top;
            }
            .final-ride-cost{
                max-width: 100px;
                word-break: break-all;
            }
            .optional-note {
                display: block;
                word-break: break-all;
                max-width: 175px;
                max-height: 100px;
                margin-bottom: 10px;
                overflow-y: auto;
            }
        }
    }
}

.log-details {
    padding: 20px;
}