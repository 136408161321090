canvas.star {
    float: left;
    z-index: 1;
}

.stars {
    visibility: hidden;
    display: inline-block;
    position: relative;
    z-index: 0;
}

.stars-selected {
    position: absolute;
    max-width: 100%;
    height: 100%;
    z-index: -1;
}