.filter-ride-order-logs{
    margin-top:10px;
    margin-bottom:0px;
    .ibox-title{
    position:relative;
    margin-bottom: 10px;
    }
    .ibox-tools{
        position:absolute;
        top: 15px;
        right: 15px;
    }
    .search-button{
        margin-right:10px;
    }
}

.ride-order-logs{
    .ibox-content{
        padding-left:0;
        padding-right:0;
    }
    .pagintion-heading{
        text-align:center;
        background-color: #fff;
        padding:15px;
    }
    .table-logs{
        width:100%;
    }
    .description{
        max-width:300px;
        word-break: break-word;
    }
}