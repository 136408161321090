.showAsDisabledInput {
    border: 1px solid;
    border-radius: 2px;
    padding: 5px;
    background-color: rgb(235, 235, 228);
    vertical-align: middle;
    box-sizing: border-box;
    border-color: initial;
}

.nopadding {
    padding: 0px !important;
  }
  .crossMe {
    font-size: 20px;
      font-weight: bolder;
      padding: 1px 0px 1px 3px;
      cursor: pointer;
      color: #4ab494;
      position: absolute;
      margin-top: -7px;
  }
  .addressFieldContainer {
    padding-top: 10px;
  }
  .addressField {
    padding: 1px 5px 1px 5px !important;
    background-color: #1ab394 !important;
    color: white !important;
    border-radius: 4px !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    max-width: 95% !important;
    white-space: nowrap;
    display: inline-block;
  }