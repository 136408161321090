tags-input {
    display: block
}

tags-input *, tags-input :after, tags-input :before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

tags-input .host {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 100%
}

tags-input .host:active {
    outline: 0
}

tags-input .tags {
    background-color: #fff;
    display: inline-block;
    padding: 4px 6px;
    color: #555;
    height: 100%;
    overflow: hidden;
    word-wrap: break-word;
    vertical-align: middle;
    border-radius: 4px;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
    border: 1px solid #e5e6e7;
    box-shadow: none;
}

.tag-list li a {
    display: inline-block;
}

tags-input .tags.focused {
    outline: 0;
}

tags-input .tags .tag-list {
    margin: 0;
    padding: 0;
    list-style-type: none
}

tags-input .tags .tag-item {
    background-color: #1ab394;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: 600;
    padding: 0 6px;
    color: #ffffff;
    text-shadow: none;
    margin-right: 2px;
    text-align: center;
    margin-bottom: 2px;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}


tags-input .tags .tag-item .remove-button {
    margin: 0 0 0 5px;
    padding: 0;
    border: none;
    background: 0 0;
    cursor: pointer;
    vertical-align: middle;
    font: 700 16px Arial, sans-serif;
    color: #ffffff
}

tags-input .tags .input.invalid-tag, tags-input .tags .tag-item .remove-button:active {
    color: red
}

tags-input .tags .input {
    border: 0;
    outline: 0;
    margin: 2px;
    padding: 0 0 0 5px;
    float: left;
    height: 26px;
    font: 14px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;

}

tags-input .tags .input::-ms-clear {
    display: none
}


tags-input[disabled] .host:focus {
    outline: 0
}

tags-input[disabled] .tags {
    background-color: #eee;
    cursor: default
}

tags-input[disabled] .tags .tag-item {
    opacity: .65;
}

tags-input[disabled] .tags .tag-item .remove-button {
    cursor: default
}

tags-input[disabled] .tags .tag-item .remove-button:active {
    color: #585858
}

tags-input[disabled] .tags .input {
    background-color: #eee;
    cursor: default
}

tags-input .autocomplete {
    margin-top: 5px;
    position: absolute;
    padding: 5px 0;
    z-index: 999;
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2)
}

tags-input .autocomplete .suggestion-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 280px;
    overflow-y: auto;
    position: relative
}

tags-input .autocomplete .suggestion-item {
    padding: 5px 10px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font: 16px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #000;
    background-color: #fff
}

tags-input .autocomplete .suggestion-item.selected, tags-input .autocomplete .suggestion-item.selected em {
    color: #fff;
    background-color: #0097cf
}

tags-input .autocomplete .suggestion-item em {
    font: normal 700 16px 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #000;
    background-color: #fff
}
