.square-meeting-request-box {
    background-color: #3a87ad;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 20px;
}

.square-meeting-confirm-box {
    background-color: #FFBF00;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 20px;
}

.square-meeting-cancel-box {    
    background-color: #ef5352;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 20px;
}

.square-meeting-complete-box {
    background-color: #1ab394;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 20px;
}

.modal-body {
    padding: 20px 30px 30px 30px;
}
.inmodal .modal-body {
    background: #f8fafb;
}
.inmodal .modal-header {
    padding: 30px 15px;
    text-align: center;
}
.animated.modal.fade .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
}
.inmodal .modal-title {
    font-size: 26px;
}
.inmodal .modal-icon {
    font-size: 84px;
    color: #e2e3e3;
}
.modal-footer {
    margin-top: 0;
}
.tooltip {
  position: absolute;
  z-index: 2001;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;

  line-break: auto;
}
.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}
.tooltip-inner {
  max-width: 350px;
  padding: 3px 8px;
  margin-left: 20px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.normalAppointmentMap {
    width:100%;
    position:relative
}
#appointmentMapParent.affix {
    top: 0;
    width: 100%;
    position: fixed !important;
}

#appointmentMapParent {
    position:relative;
    overflow: hidden;
}

@media (min-width: 851px) {
    #appointmentMapParent.affix {
      position: fixed;
  }
}
@media (max-width: 850px) {
    #appointmentMapParent.affix,  #appointmentMapParent.affix-bottom {
        position: relative !important;
        top: 0 !important;
    }
}