.mas-invoice-admin {
    .page-heading {
        padding-bottom: 0;
    }
    .wrapper-content {
        padding-top: 0;
    }
    .export-button {
        .ibox-tools.dropdown {
            display: inline-block;
        }
    }

    .exported-list-ibox {
        .ibox {
            border: none;
            box-shadow: none;
            border-bottom: none !important;
            margin-bottom: 0;
        }

        .ibox-content {
            border-top: none;
            padding: 0;
        }
    }
    .enteries{
        display: flex;
        padding: 15px;
       .input-sm{
           width:30%;
           padding: 0;
           margin: 0 3px;
       }
       span{
           padding-top:5px;
       }
    }
    .m-b-xs{
        padding-top: 15px;
    }
}
.mas-invoice-admin-import {
    .customFileInputContainer {
        display: flex !important;
    }
    .customFileInputContainer .fileinput-filename {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        min-width: 370px;
        max-width: 400px;
    }
    .uploadImport {
        margin-left: 20px;
        display: inline-block;
        vertical-align: top;
        padding: 7px 14px;
    }
}