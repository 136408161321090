.secure-email {
    .ibox-content {
        height: 350px;
        .right-container {
            border-left: 2px solid #f3f2f4 !important;
            height: 80%;
        }
        .row {
            height: 100%;
            line-height: 25px;
            margin: 5% 0px;
        }
    }
    .loading{
        left: 0 !important;
        width: 100% !important;
    }
}
.view-email-modal .modal-dialog {
    width: 990px;
    .subject {
        font-size: 17px !important;
        padding-left: 13px;
        span {
            padding-left: 5px;
        }
    }
}