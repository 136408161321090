.message-container {
  .messagebold {
    font-weight: bold;
  }
}

.notification-indicator {
  ul {
    max-width: 50vw;
    li {
      padding: 0 !important;
      a {
        padding: 1rem 1.6rem !important;
        display: flex;
        flex-direction: column;
        line-height: 1.15rem;
        &.btn-view-more {
          color: #18a689;
          flex-direction: row;
          justify-content: space-between;
          .mini-loader {
            margin: 0;
            height: 16px;
            width: 16px;
          }
        }
        .message-title {
          display: flex;
          justify-content: space-between;
        }
        div {
          font-size: small;
          min-width: 45vw;
          word-break: break-word;
          line-height: initial;
          text-transform: initial;
        }
      }
    }
  }
}

#scrollTable{
  .table-fixed{
    width: 100%;
    tbody{
      height:200px;
      overflow-y:auto;
      width: 100%;
      }
    thead,tbody,tr,td,th{
      display:block;
    }
    tbody{
      td{
        float:left;
      }
    }
    thead {
      tr{
        th{
          float:left;
         background-color:#f0f0f0;
         border-color:#f0f0f0;
        }
      }
    }
    tbody {
      tr{
        td{
          float:left;
        }
      }
    }
  }
  
}