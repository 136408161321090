@import "../../css/_variables";

.current-edit-reciept-container {
  overflow-y: auto;
  header {
    text-align: center;
    a {
      position: absolute;
      top: 15px;
      right: 15px;
    }
    h2 {
      margin-bottom: 20px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    .form-group:not(.uib-time) {
      display: flex;
      align-items: center;
      // border-bottom: 2px solid #eee;
      .icon {
        width: 20px;
        font-size: 140%;
        margin-right: 5px;
        // color: #a7b1c2;
      }
      .control-label {
        padding-left: 10px;
        text-transform: uppercase;
        font-weight: 100;
        font-size: 80%;
        font-family: $segoe-semibold;
        display: flex;
        align-items: center;
        color: #676a6c;
      }

      .input-group {
        min-width: 196px;
      }
    }
    .fare-form-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      input {
        font-size: 91%;
        margin: 10px 0;
      }
      input[type="text"] {
        width: 53%;
      }
      input[type="number"] {
        width: calc(41% - 10px);
        appearance: textfield;
        -moz-appearance: textfield;
        -webkit-appearance: none;
      }
      a {
        .fa-close {
          color: #ed5565;
        }
      }
    }

    .fare-form-group > .text-error {
      min-width: 100%;
      color:red;
      padding-left: 53%;
      font-size: 11.5px;
    }
    .driver {
      display: flex;
    }
    .total-fare {
      padding: 15px 5px;
      .lead {
        margin-bottom: 0;
      }
    }
    .duration {
      .flex-row {
        align-items: center;
      }
      input {
        width: 50px;
        font-size: 13px;
        padding: 3px 10px;
        height: 25px;
        appearance: textfield;
        -moz-appearance: textfield;
        -webkit-appearance: none;
        text-align: center;
      }
      span {
        padding: 0 10px 0 3px;
      }
    }
    .address-container {
      display: flex;
      align-items: start;
      margin-bottom: 15px;
      > div:first-child {
        width: calc(100% - 196px);
      }
      .form-group {
        margin-bottom: 0;
        .input-group {
          width: 100%;
          padding-right: 15px;
        }
        a.btn {
          padding: 0;
        }
      }
      .uib-time {
        input {
          font-size: 13px;
          padding: 3px 10px;
          height: 25px;
          margin: 0 5px;
        }
        button {
          font-size: 13px;
          padding: 3px 10px;
          height: 25px;
          margin: 0 5px;
        }
      }
    }
    fieldset {
      padding: 10px 16px;
      header {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
        color: #676a6c;
        h3 {
          margin: 10px 0;
        }
        i,
        img {
          margin-right: 10px;
          width: 20px;
          height: 20px;
          font-size: 20px;
        }
      }
      .form-group {
        margin-bottom: 34px;
      }
    }
  }
  footer {
    display: flex;
    justify-content: center;
  }
  .flex-row {
    display: flex;
    > div {
      width: 50%;
    }
  }
  #confirmation-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: whitesmoke;
    > div {
      margin: 3rem;
      .h3 {
        margin: 0 0 3rem;
      }
      textarea {
        min-width: 100%;
        max-width: 100%;
        min-height: 16rem;
      }
      .button-group {
        display: flex;
        justify-content: center;
        button {
          margin: 0 0.52rem;
          padding: 1rem 3rem;
        }
      }
    }
  }
}

.current-invoice-container {
  .add-ride-button {
    width: 100%;
    padding: 14px 0;
    font-family: $segoe-semibold;
    background-color: #f3f3f3;
    color: #1ab394;
    text-transform: uppercase;
  }
}

.invoice-listing {
  padding-bottom: 20px;

  .radio input[type="radio"] {
    opacity: 0;
    z-index: -1;
  }

  .message-padding {
    padding: 20px;
  }

  form[name="invoicesFilter"] {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
}

.invoice-details {
  .radio input[type="radio"] {
    opacity: 0;
    z-index: -1;
  }

  .message-padding {
    padding: 20px;
  }
}
.total-fare-container {
  .total-fare-helper-text{
    min-width: 100%;
    color:red;
    font-size: 11.5px;
    margin-left: 2px;
  }
}
