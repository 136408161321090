.export-container {
  position: relative;
  .export-button {
    position: absolute;
    top: 35px;
    right: 15px;
    button {
      display: flex;
      .fa {
        color: white;        
      }
    }
  }
  .ibox {
    margin: 0;
    .ibox-content {
      border: none;
      padding: 15px 0;
    }
  }
}
.receipt-detail-logs-export{
  .export-button{
    top:-35px;
  }
  .date-picker{
    background-color: #ffffff!important;
  } 
}