.web-tracking {
  padding: 15px 10px 0;

  .ibox-content {
    padding: 0;
  }

  .ride-detail-heading {
    text-align: center;
    margin: 0;
    padding: 25px 0;
  }

  .ride-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #d4d6d9;
    padding-bottom: 25px;
    position: relative;
    .single-status-container {
      width: 40px;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      span {
        margin-bottom: 2px;
        color: #737373;
      }
      .main-marker-cont {
        .marker-cont {
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid #d4d6d9;
          color: #fff;
        }
      }
    }
  }

  .status-line {
    width: 12vw;
    height: 2px;
    margin: 34px 0 0;
    background-color: #d4d6d9;
  }

  .track-driver-detail {
    padding: 25px 0;
    border-bottom: 1px solid #d4d6d9;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .tracking-driver-detail-cont {
      display: flex;
      align-items: center;
      margin: 10px;
      .track-driver-image {
        margin-right: 16px;
        max-width: 52px;
        border-radius: 50%;
      }
      & > div {
        display: flex;
        flex-direction: column;
      }
    }
    .provider-info {
      text-align: center;
    }
    & > div {
      align-items: center;
    }
  }

  .patient-detail {
    & > div {
      display: flex;
      flex-direction: column;
      line-height: 1.33em;
    }
  }

  .tracking-detail-cont {
    padding: 2.5vh 7vw;
    border-bottom: 1px solid #d4d6d9;
    display: flex;
    justify-content: space-around;
    @media (max-width: 430px) {
      flex-direction: column;
    }
  }

  .tracking-page-label {
    color: #888888;
  }

  .help-text-track {
    color: #888888;
    text-align: center;
    padding: 20px 15px;
  }

  @media (max-width: 430px) {
    .status-line {
      top: 51px;
    }
    .single-status-container span {
      font-size: 11px;
    }
  }
  @media (max-width: 360px) {
    .single-status-container span {
      font-size: 9px;
    }
    .status-line {
      top: 39px !important;
    }
  }
}
